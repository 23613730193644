fabric.LaserBrush = fabric.util.createClass(fabric.BaseBrush, /** @lends fabric.SprayBrush.prototype */ {

    initialize: function (canvas) {
        this.canvas = canvas;
        this.lastEnd = null;
    },

    onMouseMove: function (points) {
        this.drawPath(points);
    },

    drawPath: function (points) {
        var ctx = this.canvas;
        var pathString = this.canvas.freeDrawingBrush.convertPointsToSVGPath(points).join('');
        var freeDraw = new fabric.Path(pathString);
        freeDraw.set({
            fill: null,
            stroke: this.canvas.freeDrawingBrush.color,
            opacity: 1,
            strokeWidth: this.canvas.freeDrawingBrush.width,
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
            originX: 'left',
            originY: 'top',
            selectable: false
        });
        this.canvas.add(freeDraw);
        freeDraw.animate('opacity', 0, {
            duration: 1000,
            onComplete: function () {
                ctx.remove(freeDraw);
            }
        });
        this.canvas.renderAll();
    }
});