fabric.LineArrow = fabric.util.createClass(fabric.Line, {
    type: 'lineArrow',
    initialize: function (element, options) {
        options = options || {};
        this.callSuper('initialize', element, options);
    },

    toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'));
    },

    _render: function (ctx) {
        this.callSuper('_render', ctx);

        if (this.width === 0 || this.height === 0 || !this.visible) {
            return;
        }

        ctx.save();

        var xDiff = this.x2 - this.x1;
        var yDiff = this.y2 - this.y1;
        var angle = Math.atan2(yDiff, xDiff);
        ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-7, 5);
        ctx.lineTo(-7, -5);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
    }
});

fabric.LineArrow.fromObject = function (object, callback) {
    if (callback) {
        callback(new fabric.LineArrow([object.x1, object.y1, object.x2, object.y2], object));
    }
};

fabric.LineArrow.async = true;

var Arrow = (function () {
    function Arrow(canvas, arrowThickness, fadeOut) {
        this.canvas = canvas;
        this.className = 'Arrow';
        this.isDrawing = false;
        this.arrowThickness = arrowThickness;
        this.fadeOut = fadeOut;
        this.bindEvents();
    }

    Arrow.prototype.bindEvents = function () {
        var instance = this;
        instance.canvas.on('object:moving', function () {
            instance.disable();
        });
    };

    Arrow.prototype.onMouseUp = function () {
        var instance = this;
        if (instance.fadeOut) {
            var obj = instance.canvas.getActiveObject();
            obj.animate('opacity', 0, {
                duration: 1000,
                onChange: instance.canvas.renderAll.bind(instance.canvas),
                onComplete: function () {
                    instance.canvas.remove(obj);
                }
            });
        } else {
            instance.disable();
        }
    };

    Arrow.prototype.onMouseMove = function (event) {
        var instance = this;
        if (!instance.isEnable()) {
            return;
        }
        var pointer = event.pointer;
        var activeObj = this.canvas.getActiveObject();
        activeObj.set({
            x2: pointer.x,
            y2: pointer.y
        });
        activeObj.setCoords();
        instance.canvas.renderAll();

    };

    Arrow.prototype.onMouseDown = function (event) {
        var instance = this;
        instance.enable();
        var pointer = event.pointer;

        var points = [pointer.x, pointer.y, pointer.x, pointer.y];
        var line = new fabric.LineArrow(points, {
            strokeWidth: instance.arrowThickness,
            fill: 'rgba(0,0,0,0)',
            stroke: instance.canvas.freeDrawingBrush.color,
            originX: 'center',
            originY: 'center',
            hasBorders: false,
            hasControls: false,
            selectable: false
        });
        instance.canvas.add(line).setActiveObject(line);
    };

    Arrow.prototype.isEnable = function () {
        return this.isDrawing;
    };

    Arrow.prototype.enable = function () {
        this.isDrawing = true;
    };

    Arrow.prototype.disable = function () {
        this.isDrawing = false;
    };

    return Arrow;
})();